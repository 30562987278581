export default (visible: boolean) => {
    if (window) {
        const j = window.document.getElementsByTagName("jdiv") as HTMLCollectionOf<HTMLElement>;
    
        if (j.length > 0) {
            if (visible) {
                j[0].style.display = "block";
            } else {
                j[0].style.display = "none";
            }
        }
    }
};
