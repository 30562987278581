<template>
    <div>
        <!-- <img v-if="colorMode.preference == 'light'" src="@/assets/Logo_Plunni_5.png" class="h-4 w-auto" alt="Logo Plunni" />
        <img v-if="colorMode.preference != 'light'" src="@/assets/Logo_Plunni_6.png" class="h-4 w-auto" alt="Logo Plunni" /> -->
        <ULink to="/" class="font-prosto" >
            Plunni Hire
        </ULink>
    </div>
</template>

<script setup>
const colorMode = useColorMode()
</script>